import { graphql, useStaticQuery } from "gatsby"

export default function useOverOnsImages() {
  return (useStaticQuery(graphql`
    query MyQuery {
      allFile(
        filter: {absolutePath: {regex: "/over-ons/images/"}}
        sort: {fields: base, order: ASC}
      ) {
        nodes {
          id
          childImageSharp {
            gatsbyImageData
          }
          base
        }
      }
    }
  `)).allFile.nodes;
}
