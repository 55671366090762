import * as React from "react"
import { Accordion } from "react-bootstrap"
import useOverOnsItems from "../hooks/useOverOnsItems"

export default function OverOnsAccordion() {
  const items = useOverOnsItems();

  return (
    <Accordion className="mb-2">
      {
        items && items.map((item, index) => {
          const title = item.frontmatter?.title || "";
          const firstWordChar = title.slice(0, 1);
          const words = title.split(" ");
          return (
            <Accordion.Item key={item.id} eventKey={`${index}`}>
              <Accordion.Header>
                <span className="">
                  {
                    words.map((word, index) => {
                      return (
                        <React.Fragment key={word}>
                          {
                            word.startsWith(firstWordChar)
                              ? (
                                <React.Fragment>
                                  <span className="fs-4 fw-bold">{word.slice(0, 1)}</span>
                                  {word.slice(1)}
                                </React.Fragment>
                              )
                              : (
                                <>{word}</>
                              )
                          }
                          {index + 1 !== words.length ? " " : undefined}
                        </React.Fragment>
                      )
                    })
                  }
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <div dangerouslySetInnerHTML={{ __html: item.html || "" }}/>
              </Accordion.Body>
            </Accordion.Item>
          )
        })
      }
    </Accordion>
  )
}
