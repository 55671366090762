import { graphql, useStaticQuery } from "gatsby"

export default function useOverOnsItems() {
  return (useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/over-ons/"}}
        sort: {order: ASC, fields: frontmatter___order}
      ) {
        nodes {
          id
          html
          frontmatter {
            title
          }
        }
      }
    }
  `)).allMarkdownRemark.nodes;
}
