import * as React from "react"
import useOverOnsImages from "../hooks/useOverOnsImages"
import { Col, Row } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


function OverOnsImage({ image }) {
  return (
    <Col xs={12} sm={6} md={4} className="mb-6">
      <GatsbyImage alt={image.base} image={getImage((image))} />
    </Col>
  )
}

export default function OverOnsImages() {
  const images = useOverOnsImages();

  return (
    <Row>
      {
        images && images.map(image => {
          return (
            <OverOnsImage key={image.base} image={image} />
          )
        })
      }
    </Row>

  )
}
