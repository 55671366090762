import * as React from "react"
import { Layout } from "../components/layout"
import { Col, Container, Row } from "react-bootstrap"
import OverOnsAccordion from "../components/OverOnsAccordion"
import OverOnsImages from "../components/OverOnsImages"
import { Seo } from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"


export default function OverOns() {
  return (
    <Layout>
      <Container className="pt-7 pb-4">
        <h1 className="display-2 fw-bold text-center">Smakelijke kennismaking!</h1>
        <figure className="text-center">
          <blockquote className="blockquote">
            <p>Je kunt ook op een lekkere manier <em>suikerminderen</em>.</p>
          </blockquote>
          <figcaption className="blockquote-footer">
            Heidi Neven, zaakvoerster Zelfgemaakt
          </figcaption>
        </figure>
        <Row className="justify-content-center mb-2">
          <Col xl={10} xxl={8} className="mb-4 text-center">
            <StaticImage src="../../static/img/heidi.jpg" alt="Heidi met producten" />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center mb-6">
          <Col xxl={8} xl={10}>
            <p>
              Ik ben Heidi, bezielster van Zelfgemaakt. Enkele jaren geleden besloot ik om een lang gekoesterde droom na te
              jagen: lekkere dingen maken, die tegelijk gezond zijn. Zonder schadelijke additieven, maar puur natuur. Met
              een pak minder suiker dan de producten uit de supermarkt.
            </p>
            <p>
              Het begon allemaal met een granola uit lokale granen, pitten, noten met een vleugje honing van de plaatselijke
              imker. Maar al snel groeide Zelfgemaakt uit tot een productgamma om van te smullen. Zelfgemaakte confituren,
              pittige chutneys, zalige likeuren en romige advocaat. Stuk voor stuk gebaseerd op familierecepten of eigen
              ideeën. Kortom, hét bewijs dat suikerminderen ook op een lekkere manier kan!
            </p>
          </Col>
        </Row>
        <h2 className="text-center mb-4">Mijn visie op (h)eerlijke producten: ZELF</h2>
        <OverOnsAccordion />
        <p className="text-center fw-bold mb-6">
          Da’s dus Zelfgemaakt.<br/>
          Voor de volle 100%.
        </p>
        <OverOnsImages />
      </Container>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Smakelijke kennismaking! | Over ons"
    description="Gezonde producten met minder suiker saai? Wacht maar tot je de plezante potjes van Zelfgemaakt leert kennen."
  />
)
